<template>
    <div>
        <div class="modal-header">
            <div class="modal-title">
                <a-icon type="medicine-box" style="margin-right:10px;" />
                <span>{{this.isEdit?l('Edit'):l('Create')}}</span>
            </div>
        </div>
        <div>
            <a-config-provider :locale="zh_CN">
                <a-row>
                    <a-col :span="24">
                        <a-form-item required :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }" :label="l('Name')"
                            class="body">
                            <a-input v-model="entity.name" class="input"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :span="24">
                        <a-form-item required :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }" :label="l('Barcode')"
                            class="body">
                            <a-input v-model="code" class="input"></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col :span="15">
                        <a-form-item required :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }"
                            :label="l('SubStart')" class="body">
                            <a-input-number v-model="entity.subStart" style="width: 100%;"
                                class="input"></a-input-number>
                        </a-form-item>
                    </a-col>
                    <a-col :span="9">
                        <a-form-item :label-col="{ span: 8 }" :wrapper-col="{ span: 3 }" :label="l('SubLength')"
                            class="body">
                            {{entity.subLength == null ? 0 :entity.subLength}}
                        </a-form-item>
                     </a-col>
                    <a-col :span="18" offset="5" style="margin-bottom: 20px;">
                        {{l('Barcode_promptss')}}
                    </a-col>

                    <a-col :span="24">
                        <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 19}"
                            :label="l('AssitField')" class="body">
                            <a-col :span="11" style="text-align: left;">
								{{l('SubStart') + '：'}}
								<a-input-number v-model="entity.assitFieldSubStart" style="width: 70px"></a-input-number>
                            </a-col>
                            <a-col :span="12" style="text-align: left;">
								{{l('SubLength') + '：'}}
								<a-input-number v-model="entity.assitFieldSubLength" style="width: 70px"></a-input-number>
                            </a-col>
                        </a-form-item>
                    </a-col>
                    <!-- <a-col :span="8">
                        <a-form-item :label-col="{ span: 14 }" :wrapper-col="{ span: 9, offset: 1 }"
                            :label="l('AssitField')" class="body">
                            <a-input-number v-model="entity.assitFieldSubStart" class="input"></a-input-number>
                        </a-form-item>
                    </a-col>
                    <a-col :span="8">
                        <a-form-item :label-col="{ span: 14 }" :wrapper-col="{ span: 9, offset: 1 }"
                            :label="l('AssitField')" class="body">
                            <a-input-number v-model="entity.assitFieldSubStart" class="input"></a-input-number>
                        </a-form-item>
                    </a-col>
                    <a-col :span="7">
                        <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }" class="body">
                            <a-input-number v-model="entity.assitFieldSubLength" class="input"></a-input-number>
                        </a-form-item>
                    </a-col> -->
                    <a-col :span="24">
                        <a-form-item :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }" :label="l('Remark')"
                            class="body">
                            <a-input v-model="entity.remark" class="input"></a-input>
                        </a-form-item>
                    </a-col>
                </a-row>
            </a-config-provider>
        </div>
        <div class="modal-footer" v-if="!isLook">
            <a-button @click="close">
                {{l('Cancel')}}
            </a-button>
            <a-button :type="'primary'" @click="save">
                {{l('Save')}}
            </a-button>
        </div>
    </div>
</template>

<script>
    import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
    import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
    import { TemplateBarCodeServiceProxy, TemplateBarCodeEditDto } from '../../../../shared/service-proxies';

    let _this;
    export default {
        name: 'createorupdatebarcode',
        mixins: [ModalComponentBase],
        components: {

        },
        created() {
            _this = this;
            this.fullData(); // 模态框必须,填充数据到data字段
            this.TemplateBarCodeServiceProxy = new TemplateBarCodeServiceProxy(this.$apiUrl, this.$api);
        },
        mounted() {
            if (this.isEdit) {
                this.getData();
            }
        },
        data() {
            return {
                zh_CN,
                isEdit: false,//是否是编辑
                isLook: false,//是否是查看
                entity: new TemplateBarCodeEditDto(),
                code: undefined,
                isclick: true,
            }
        },
        watch: {
            code: {
                handler(Value) {
                    if (Value != null) {
                        _this.entity.subLength = Value.length;
                    }
                }
            }
        },
        methods: {
            getData() {
                this.isLoading = true;
                this.TemplateBarCodeServiceProxy.getForEdit(
                    this.id
                ).finally(() => {
                    this.isLoading = false;
                }).then(res => {
                    console.log(res);
                    this.entity = res;
                    this.code = res.code;
                })
            },

            save() {
                if (!this.entity.name) {
                    abp.message.info('名称不能为空');
                    return;
                }
                this.entity.code = this.code;
                var createOrUpdateTemplateBarCode = { TemplateBarCode: this.entity };
                // console.log(auditItemTypeEditDto);
                if (this.isclick) {
                    this.isclick = false;
                    this.TemplateBarCodeServiceProxy.createOrUpdate(
                        createOrUpdateTemplateBarCode
                    ).finally(() => {
                        this.close()
                    }).then(res => {
                        this.isclick = true;
                        this.success(true)
                    })
                }
            },

        }
    }
</script>

<style>
    .input {
        width: 100%;
    }

    .body {
        margin-bottom: 10px;
    }
</style>